import React, { useEffect, useState } from "react";
import { columnasTablaRendimientos, handleFormatRendimientos } from "./Utils";
import { getRendimientosRepuestoProveedorService } from "../Services";
import BotonDescargarRendimientos from "./BotonDescargarRendimientos";
import ReportTable from "../../../components/ait-reusable/Report/ReportTable";

export default function TablaRendimientos({ idRepuestoProveedor }) {
  const [isLoading, setIsLoading] = useState(false);
  const [rendimientos, setRendimientos] = useState([]);

  const [pagination, setPagination] = useState({
    page: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });

  const handleGetRendimientos = async () => {
    setIsLoading(true);
    try {
      const response = await getRendimientosRepuestoProveedorService(
        idRepuestoProveedor,
        pagination.page,
      );

      setRendimientos(response.items);
    } catch (error) {
      console.log(error);
      setRendimientos([]);
      setPagination({
        ...pagination,
        page: 1,
        num_items: 0,
        next_page: null,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetRendimientos();
  }, [pagination.page]);

  return (
    <ReportTable
      loading={isLoading}
      itemsCountPerPage={15}
      pagination={pagination}
      setPagination={setPagination}
      columns={columnasTablaRendimientos}
      items={handleFormatRendimientos({
        rendimientos,
        idRepuestoProveedor: idRepuestoProveedor,
      })}
    >
      <BotonDescargarRendimientos idRepuestoProveedor={idRepuestoProveedor} />
    </ReportTable>
  );
}
